
.btn {
  @apply w-fit px-3.5 py-2.5 text-sm font-semibold shadow-sm rounded-md focus-visible:ring disabled:cursor-not-allowed border outline-none;
  @apply disabled:bg-opacity-70 disabled:hover:bg-opacity-70 #{!important};

  &-sm {
    @apply px-3 py-2 text-xs #{!important};
  }

  &-primary {
    @apply bg-indigo-500 text-white hover:bg-indigo-600 border-transparent focus-visible:ring-indigo-300;

    &-outline {
      @apply bg-white text-gray-900 hover:bg-indigo-500 hover:text-white disabled:text-gray-400 disabled:hover:bg-white border-indigo-500 focus-visible:ring-indigo-300;
    }
  }

  &-info {
    @apply bg-blue-500 text-white hover:bg-blue-600 border-transparent focus-visible:ring-blue-300;

    &-outline {
      @apply bg-white text-gray-900 hover:bg-blue-500 hover:text-white disabled:text-gray-400 disabled:hover:bg-white border-blue-500 focus-visible:ring-blue-300;
    }
  }

  &-success {
    @apply bg-green-500 text-white hover:bg-green-600 border-transparent focus-visible:ring-green-300;

    &-outline {
      @apply bg-white text-gray-900 hover:bg-green-500 hover:text-white disabled:text-gray-400 disabled:hover:bg-white border-green-500 focus-visible:ring-green-300;
    }
  }

  &-warning {
    @apply bg-yellow-500 text-white hover:bg-yellow-600 border-transparent focus-visible:ring-yellow-300;

    &-outline {
      @apply bg-white text-gray-900 hover:bg-yellow-500 hover:text-white disabled:text-gray-400 disabled:hover:bg-white border-yellow-500 focus-visible:ring-yellow-300;
    }
  }

  &-danger {
    @apply bg-red-500 text-white hover:bg-red-600 border-transparent focus-visible:ring-red-300;

    &-outline {
      @apply bg-white text-gray-900 hover:bg-red-500 hover:text-white disabled:text-gray-400 disabled:hover:bg-white border-red-500 focus-visible:ring-red-300;
    }
  }

  &-secondary {
    @apply bg-gray-400 text-white hover:bg-gray-500 border-transparent focus-visible:ring-gray-300;

    &-outline {
      @apply bg-white text-gray-900 hover:bg-gray-400 hover:text-white disabled:text-gray-400 disabled:hover:bg-white border-gray-400 focus-visible:ring-gray-300;
    }
  }

  &-dark {
    @apply bg-gray-800 text-white hover:bg-gray-900 border-transparent focus-visible:ring-gray-500;

    &-outline {
      @apply bg-white text-gray-900 hover:bg-gray-900 hover:text-white disabled:text-gray-400 disabled:hover:bg-white border-gray-800 disabled:border-gray-400 focus-visible:ring-gray-500;
    }
  }

  &-light {
    @apply bg-gray-100 text-gray-900 hover:bg-gray-300 border-transparent focus-visible:ring-gray-50;

    &-outline {
      @apply bg-white text-gray-900 hover:bg-gray-100 disabled:text-gray-400 disabled:hover:bg-white border-gray-300 focus-visible:ring-gray-50;
    }
  }
}