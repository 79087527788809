
.card {
  @apply flex flex-col rounded-lg border border-gray-200 bg-white shadow-md relative;

  &-header {
    @apply flex px-6 py-5 border-b items-center justify-between;

    h3 {
      @apply font-semibold leading-6;
    }

    &-actions {
      @apply flex items-center;
    }
  }

  &-body {
    @apply flex h-full flex-col justify-center gap-4 px-6 py-5;
  }
}