

.form {
  &-input-group {
    @apply mb-3
  }

  &-label {
    @apply block text-sm font-medium leading-6 text-gray-900;
  }

  &-input {
    @apply block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6 px-3;
    @apply focus:ring-2 focus:ring-inset focus:ring-indigo-600;
    @apply disabled:cursor-not-allowed disabled:opacity-50;

    &-invalid {
      @apply ring-1 ring-inset ring-red-400;
      @apply focus:ring-2 focus:ring-inset focus:ring-red-400;
    }
  }

  &-select {
    @apply block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6;
    @apply focus:ring-2 focus:ring-inset focus:ring-indigo-600;
    @apply disabled:cursor-not-allowed disabled:opacity-50;

    &-invalid {
      @apply ring-1 ring-inset ring-red-400;
      @apply focus:ring-2 focus:ring-inset focus:ring-red-400;
    }
  }

  &-checkbox-group {
    @apply relative flex gap-x-3 mb-3;
  }

  &-checkbox-wrap {
    @apply flex h-6 items-center;
  }

  &-checkbox {
    @apply h-4 w-4 rounded border-gray-300 text-indigo-600;
    @apply focus:ring-indigo-600;
    @apply disabled:cursor-not-allowed disabled:opacity-50;
  }

  &-checkbox-label-wrap {
    @apply text-sm leading-6;

    label {
      @apply font-medium text-gray-900;
    }
  }

  &-radio-group {
    @apply flex gap-x-5;
  }

  &-radio-wrap {
    @apply flex items-center gap-x-3;
  }

  &-radio {
    @apply h-4 w-4 border-gray-300 text-indigo-600;
    @apply focus:ring-indigo-600;
    @apply disabled:cursor-not-allowed disabled:opacity-50;
  }

  &-radio-label {
    @apply block text-sm font-medium leading-6 text-gray-900;
  }

  &-text {
    @apply text-sm leading-6 text-gray-400;
  }

  &-invalid-text {
    @apply text-sm leading-6 text-red-400;
  }
}