@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,100;9..40,200;9..40,300;9..40,400;9..40,500;9..40,600;9..40,700;9..40,800;9..40,900;9..40,1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;200;300;400;500;600;700;800;900&display=swap');

html, body, #root {
    width: 100%;
    height: 100%;
}

#portal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    overflow: auto;
    background-color: #ffffff;
    z-index: 10;
    display: block;
    transform: translateZ(1000px);
}

#portal:empty {
    display: none !important;
}

@layer components {
    @import "tailwindcss/button";
    @import "tailwindcss/card";
    @import "tailwindcss/form";
    @import "tailwindcss/table";
}

#webpack-dev-server-client-overlay {
    display: none;
}
@layer utilities {

}

