.table {
  @apply min-w-full;

  tbody {
    tr {
      @apply border-t;
    }
  }

  tr {
    th, td {
      @apply text-left first:pl-0 last:pr-0 leading-5 py-3.5 px-3 text-sm;
    }

    th {
      @apply font-semibold;
    }
  }
}